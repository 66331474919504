<template>
  <div style="width: 100%">
    <div class="container">
      <page-header-image />
      <div style="margin-top: 100px"></div>
      <div class="contentContainer">
        <aside class="slideBar">
          <div class="slidebarItem active">
            <p class="title">Lideastech (Shenzhen) Co., Ltd</p>
            <img
              class="vector activeVector"
              src="..\static\img\Vector.png"
              alt=""
            />
          </div>
        </aside>
        <main class="content">
          <img class="logo"
            src="..\static\img\logo2.png"
            alt="LOGO"
          />
          <article>
            <p style="text-indent: 2em">
              Lideastech (Shenzhen) Co., Ltd. is a wholly-owned subsidiary of Baodeshi (Shenzhen) Ltd. was established. and was established in Pingshan District, Shenzhen in March 2019. In response to the national and Shenzhen industrial upgrading policies, the company is a technology-based company focusing on R&D, design and innovation. The main research and development project directions include: design, research and development and management of human health massage equipment, medical and health auxiliary products, high-tech electronic products, electronic medical equipment, AI artificial intelligence, integrated circuits and small household appliances, information technology consulting, etc. business. The company has obtained 4 utility model patents and 3 invention patents for the record.
            </p>
            <br />
            <p style="text-indent: 2em">
              The vision of the company's establishment is to devote itself to the design and research and development of high-tech electronic circuit boards, artificial intelligence, human health and medical equipment industries.
            </p>
            <br />
            <p style="text-indent: 2em">
              The company's goal: to obtain more than 5 national invention and utility model patents within five years, and to pass the national high-tech enterprise certification; to become the top high-tech patent invention enterprise in Pingshan District, Shenzhen within ten years.
            </p>
            <br />
            <p style="text-indent: 2em">
              The company's existing office area is more than 300 square meters, and the office building is located in Block C, Innovation Plaza, Government Industrial Zone. The company has established a highly educated and high-quality engineering development and original design team.
            </p>
            <br />
            <p style="text-indent: 2em">
              The company's management system adopts the ISO9001: 2015 quality management system model. To provide high-end, high-quality and satisfactory product design and service to our customers is the unremitting goal of Lehuibang. To create more and better patents is our unremitting vision and goal.
            </p>
            <br /><br /><br />

            <p>
Office Address: Unit C1921-26, Block C, Innovation Plaza, No. 2007, Pingshan Avenue, Pingshan District, Shenzhen
            </p>
            <p>Contact: Mr. Xie Jianzhong</p>
            <p>Tel: (0755)61862318</p>
            <p>Fax:(0755)61862319</p>
          </article>
        </main>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeaderImage from "../components/PageHeaderImage.vue";

export default {
  components: { PageHeaderImage },
  data() {
    return {
      isActive: 0,
    };
  },
  methods: {
    setActive(data) {
      this.isActive = data;
    },
  },
};
</script>

<style scoped>
* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
}

.contentContainer {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 70px;
  position: relative;
  left: 5%;
  width: 90%;
}

.slideBar {
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 476.25px;
  width: 100%;
}

.slidebarItem {
  position: relative;
  width: 100%;
  height: 80px;
  background: #1f89eb;
  margin-top: 10px;
  font-family: PingFang SC;
  font-style: normal;
  font-size: 32px;
  /*   line-height: 80px; */
  color: #ffffff;
  padding-left: 30px;
  text-align: start;
  transition: all 0.5s;
  cursor: pointer;
  padding-top: 20px;
}

.title{
  width: 80%;
}

.logo{
  height: 206.25px;
}

.active {
  background: #041d72;
  transition: all 0.5s;
  height: 206.25px;
}

.vector {
  position: absolute;
  right: 30px;
  top: 24px;
  height: 32px;
  transition: all 0.5s;
}

.activeVector {
  transform: rotate(90deg);
}

.content {
  position: relative;
  width: 100%;
  transition: all 0.5s;
  margin-bottom: 50px;
}

.picture {
  display: block;
  margin: auto;
  width: 75%;
  margin-bottom: 50px;
}

h2 {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin-bottom: 75px;
}

.detailContainer {
  width: inherit;
  display: none;
  font-family: PingFang SC;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  text-align: justify;
  color: #0e0e0e;
  opacity: 0;
  transition: all 0.5s;
  margin-top: 10px;
}

.activeContent {
  opacity: 1;
  transition: all 0.5s;
  display: inline-block;
}

@media screen and (max-width: 1450px) {
  .contentContainer {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr auto;
  }

  .slideBar {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 80px;
    width: 100%;
    margin-bottom: 30px;
  }

  .slidebarItem {
    margin-top: 0;
    margin-right: 10px;
    padding: 0;
    text-align: center;
  }

  .vector {
    display: none;
  }

  .active {
    background: #041d72;
    transition: all 0.5s;
    height: 80px;
    line-height: 80px;
  }

  .title{
    width: 100%;
  }

  .logo{
    display: block;
    height: 150px;
    margin: auto;
  }
}

@media screen and (max-width: 750px) {
  .slidebarItem {
    font-size: 16px;
    height: 60px;
    line-height: 60px;
  }

  .slideBar {
    height: 60px;
    margin-bottom: 15px;
  }
}
</style>
